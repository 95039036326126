<template>
  <div class="home">
    <div class="container">
      <form id="form-app">
        <transition
          v-for="(question, index) in questions"
          :key="'question-' + index"
          name="slide-enter"
          mode="out-in"
        >
          <div
            class="div-cont"
            v-show="index <= count_process && index >= leave_process"
          >
            <h3
              v-html="question"
              v-show="index <= count_process"
              class="ask-type"
            >
              {{ question }}
            </h3>
            <div v-show="allowQuestions == false" class="subcontainer">
              <input
                type="text"
                :ref="index"
                :autofocus="index == 0"
                @keydown.enter="focusNext"
                v-on:keyup.enter="onEnter(index)"
                v-model="answers[index]"
                class="input-data"
              />
            </div>
          </div>
        </transition>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      questions: [
        "Gracias por aplicar para la cita, antes tenemos que comentarte lo siguiente: <br> 1. No se aceptan mascotas. <br>  2. No se rentan a extranjeros. <br> 3. Al ser una renta individual, no se puede quedar a dormir nadie que no sea la persona que renta. <br> ¿Sigues interesado?",
        "¿Cuál es tu nombre?",
        "Escribe tus apellidos:",
        "¿A que te dedicas?",
        "Danos mas detalles de ti:",
        "Proporciona un número telefónico para comunicarnos contigo:",
        "Escribe los dias de la semana que estas libre para la cita:",
        "Escribe un rango de horario para tu cita:",
      ],
      answers: [],
      count_process: 0,
      leave_process: -1,
      finishIndex: 0,
      allowQuestions: false,
    };
  },
  methods: {
    onEnter(index) {
      console.log("index es ", index);
      if (index == this.count_process) {
        if (index < this.finishIndex) {
          this.count_process += 1;
          this.leave_process += 1;
          const id_inp = "input" + this.count_process;
          console.log("Id es ", id_inp);
          console.log("View models es ", this.answers);
        } else {
          var formFilled = "";
          for (let num in this.questions) {
            formFilled +=
              "<h3>###" +
              this.questions[num] +
              "</h3><br><p>R:" +
              this.answers[num] +
              "</p><br>";
          }
          console.log("form filled es ", formFilled);
          this.questions.splice();
          this.questions = [];
          this.count_process = 0;
          this.leave_process = -1;
          this.questions.push(
            "¡Gracias por tu registro, nos comunicaremos contigo pronto!"
          );
          this.allowQuestions = true;

          this.generateForm(formFilled);
        }
        //this.generateForm();
      }
    },
    generateForm(form) {
      const axios = require("axios");
      axios
        .post("https://www.api.comfortroomies.com/mail/test", {
          form: form,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    focusNext(e) {
      const func = () => {
        const inputs = Array.from(
          e.target.form.querySelectorAll('input[type="text"]')
        );
        const index = inputs.indexOf(e.target);
        console.log("Index inutsss es ", index);
        if (index < inputs.length) {
          inputs[index + 1].focus();
        }
      };
      setTimeout(func, 800);
    },
  },
  mounted() {
    for (let num in this.questions) {
      console.log("Num es ", num);
      this.answers.push("");
    }
    this.finishIndex = this.questions.length - 1;
    console.log("Finish index es ", this.finishIndex);
  },
};
</script>

<style>
.home {
  background-color: black;
}
.container {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: black;
  width: 100%;
  height: 80vh;
}
.div-cont {
  text-align: justify;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.ask-type {
  text-align: start;
  font-size: 1.4em;
  color: white;
  margin-left: 60px;
  margin-right: 60px;
  width: auto;
}
.input-data {
  background-color: black;
  color: white;
  outline: 0;
  border-bottom: 1px solid #ffffff !important;
  border: none;
  font-size: 1.2em;
  margin-left: 60px;
  margin-right: 60px;
  width: 100%;
}

.subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.slide-enter-enter-active {
  transition: 0.4s;
}
.slide-enter-leave-active {
  transition: 0.4s;
}
.slide-enter-enter {
  transform: translateX(40%);
  opacity: 0;
}
.slide-enter-enter-to {
  transform: translateX(0%);
  opacity: 1;
}
.slide-enter-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(40%);
  opacity: 0;
}
</style>